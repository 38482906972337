<template>
  <div
    v-shortkey="['esc']"
    class="modals-privacy-policy"
    @contextmenu.self.prevent
    @click="showModalPrivacyPolicy = false"
    @shortkey="showModalPrivacyPolicy = false"
  >
    <div
      class="modals-privacy-policy__content"
      @click.stop
      @contextmenu.prevent
    >
      <div
        class="modals-privacy-policy__close"
        @click="showModalPrivacyPolicy = false"
      >
        <i class="icon-close-modal"></i>
      </div>
      <div class="modals-privacy-policy__title">
        Privacy Policy
      </div>
      <div class="modals-privacy-policy__wrapper">
        <VuePerfectScrollbar class="modals-privacy-policy__scroll">
          <div class="modals-privacy-policy__text">
            <p class="modals-privacy-policy__desc">
              We could not provide the service we do without collecting some information from our users.
              This document sets forth how we collect, protect, and manage that information.
              In this agreement,"We," "us," "ImpactFactors" or the "Company" means Impact Factors,
              LLC, a Texas limited liability company. "You" or a "User" means anyone who accesses the
              the ImpactFactors Web site or becomes a registered user of the ImpactFactors service.
            </p>
            <h4 class="modals-privacy-policy__subtitle">
              Information We Collect.
            </h4>
            <ol>
              <li>
                <strong>Internet.</strong> We may collect information about your computer's Internet address, your e-mail
                address, and the software you are using to access the site. We may also place certain files
                called "cookies" on your computer to allow our site to work more efficiently for you.
              </li>
              <li>
                <strong>Personal.</strong> For our services to operate effectively, we need information about your name,
                age, address, and other characteristics.
              </li>
              <li>
                <strong>Financial.</strong> For payment, you provide us information about your credit cards and/or bank accounts.
              </li>
              <li>
                <strong>Correspondence.</strong> E-mails or letters from you or about you, or comments from you
                or about you on our website, may be collected and kept in an electronic and/or paper file.
              </li>
              <li>
                <strong>Third Parties.</strong> E-mails or e-mail addresses of third parties (non-clients) which may appear
                on our Web site in "refer a friend" postings or otherwise may be collected and kept in an electronic and/or paper file.
              </li>
            </ol>
            <h4 class="modals-privacy-policy__subtitle">
              What We Do With the Information.
            </h4>
            <ol>
              <li>
                <strong>Internet.</strong> This information is used solely by our website and software.
              </li>
              <li>
                <strong>Personal.</strong> Personal information about our clients is used for site analysis, our own marketing,
                information, targeted advertising, and customization of our website. It may also be used for
                solving problems, resolving disputes, and enforcing our user agreement.
              </li>
              <li>
                <strong>Financial.</strong> Financial information is used only for payment purposes, and is kept confidential.
              </li>
              <li>
                <strong>Correspondence.</strong> Correspondence will only be accessed by authorized employees of
                ImpactFactors, will be kept confidential, and will not be disclosed to any third party.
              </li>
              <li>
                <strong>Third Party Information ("Tell-A-Friend").</strong>If you choose to use our referral service to tell
                a friend about our site, we will ask you for your friend's name and e-mail address.
                We will automatically send your friend a one-time e-mail inviting him or her to visit
                the site. ImpactFactors stores this information for the sole purpose of sending this
                one-time e-mail and tracking the success of our referral program. Your friend may contact
                us using the Opt-Out instructions below to request that we remove this information from our database.
              </li>
            </ol>
            <h4 class="modals-privacy-policy__subtitle">
              Disclosure of Your Information.
            </h4>
            <ol>
              <li>
                <strong>Internet.</strong> This information is not disclosed to any third party.
              </li>
              <li>
                <strong>Personal and Correspondence.</strong> Information you provide us is only disclosed to other clients
                of ImpactFactors with your express permission. We do NOT disclose your personal information
                to any third party or parties. The only information we disclose to advertisers and marketers
                are aggregate statistics, without any individual personal or financial information included.
                For legal purposes, however, we must reserve the right to disclose your personal information
                or correspondence as may be required by law, and when our counsel informs us that disclosure
                is needed to comply with a court order or subpoena served on us, or to protect our rights in
                a legal proceeding.
              </li>
              <li>
                <strong>Financial.</strong> Your financial information is only disclosed for the purpose of processing payments.
                Any company to which this information is disclosed is bound by a similar duty of confidentiality,
                and will not use this information for any other purpose or disclose it to any other party. We use
                a third party to process your credit card transactions on the Web site. The only type of service
                providers used with whom your financial information is shared are billing and payment processing
                providers.
              </li>
              <li>
                <strong>On Web Site.</strong> We post customer testimonials on our Web site which may contain
                personally identifiable information such as the customer's name. We do obtain
                the customer's consent prior to posting the testimonial to post their name along
                with their testimonial. If you want to remove your personal information that is being
                displayed on our Web site under public pages see the Opt-Out instructions below.
                (We will provide the address in a scripting language to discourage harvesting of the address
                by spam bots.) If you use a bulletin board, blog, or chat room on our Web site, you should be
                aware that any personally identifiable information you submit there can be read, collected,
                or used by other users of these forums, and could be used to send you unsolicited messages.
                We are not responsible for the personally identifiable information you choose to submit in
                these forums.
              </li>
              <li>
                We use a third party service provider to host the online help section of the site once you are logged into your account.
              </li>
            </ol>
            <h4 class="modals-privacy-policy__subtitle">
              Your Responsibilities.
            </h4>
            <ol>
              <li>
                Information About Other Clients. You are not allowed to use any ImpactFactors
                clients's information for marketing, advertising, promotion, spam, harassment,
                or threats of any kind. If any member violates this policy or violates the Terms
                of Use in any other way, ImpactFactors reserves the right to terminate his or her
                services.
              </li>
              <li>
                Your Member Name and Password. It is your responsibility to keep your ImpactFactors client
                name and password confidential, and not disclose this information to any third party.
                If anyone else obtains your member name and password, they will be able to alter your
                information and charge items to your account, and ImpactFactors cannot be held responsible
                for such actions. It is, therefore, important for your member name and password to be secure.
                If you have any reason to believe your password may have been compromised, notify us
                immediately and change your password. It is a good idea to change your password from time to time.
              </li>
              <li>
                Reviewing and Changing Your Information. As soon as you have registered as a clients
                of ImpactFactors, you can review and update some of the personal information you
                provide to the site, including your e-mail address, your mailing address,
                your password, and the personal information in your profile. You should update your
                information immediately if it changes to keep it current. Any changes should be
                communicated to ImpactFactors in one of the following ways:
                <ol>
                  <li>
                    By modifying or deleting your client profile information on ImpactFactors directly;
                  </li>
                  <li>
                    By e-mailing a request to info@impactfactors.net; or
                  </li>
                  <li>
                    By mailing a request to our Customer Service Department at ImpactFactors, LLC, 2730 N. Stemmons Freeway,
                    <br>
                    West Tower, Suite 415
                    <br>
                    Dallas, TX 75207
                  </li>
                </ol>
              </li>
            </ol>
            <h4 class="modals-privacy-policy__subtitle">
              Third Parties.
            </h4>
            <p class="modals-privacy-policy__desc">
              This privacy policy only relates to our use and disclosure of your information.
              If you provide personal information to other clients of ImpactFactors or other
              Web sites, it is not subject to this policy and may not be protected. ImpactFactors works
              with other companies to serve ads on our site, which may place cookies on your computer or use
              other technologies to make their ads more effective and targeted. <strong>ImpactFactors does not disclose
                any of your personally identifiable information to third parties.</strong>
            </p>
            <h4 class="modals-privacy-policy__subtitle">
              Security.
            </h4>
            <p class="modals-privacy-policy__desc">
              ImpactFactors employs security procedures and policies to protect your information and
              to prevent the distribution, misuse, or alteration of the data we retain. We protect our
              servers with industry standard firewalls and hacking prevention tools, and continually monitor
              and work to prevent intrusions. No one on the Internet can guarantee absolute and perfect security,
              but ImpactFactors will consistently use its best efforts to keep your information secure. When you enter
              sensitive information (such as credit card number and/or Social Security number) on our registration
              or order forms, we encrypt that information using Secure Socket Layer technology (SSL).
            </p>
            <h4 class="modals-privacy-policy__subtitle">
              Changes in this Privacy Statement.
            </h4>
            <p class="modals-privacy-policy__desc">
              If we decide to change our privacy policy, we will post those changes to this Privacy Policy,
              the home page of our Web site, and other places we deem appropriate so that you are aware
              of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
              <br><br>
              We reserve the right to modify this privacy statement at any time, so please review it
              frequently. If we make material changes to this policy, we will notify you here, by email,
              or by means of a notice on our home page.
              Effective Date of this policy: November 20, 2013.
            </p>
            <h4 class="modals-privacy-policy__subtitle">
              Contact Information.
            </h4>
            <p class="modals-privacy-policy__desc">
              To contact the site regarding this privacy policy please email us at
              <a href="mailto:info@impactfactors.net">info@impactfactors.net</a>
            </p>
            <address>
              <p class="modals-privacy-policy__desc">
                <em>Mailing Address:</em>
                <br>
                <strong>Impact Factors, LLC</strong>
              </p>
              <p class="modals-privacy-policy__desc">
                3626 N Hall Str.
                <br>
                Suite 610
                <br>
                Dallas TX 75244
                <br>
                USA
              </p>
            </address>
          </div>
        </VuePerfectScrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'ModalsPrivacyPolicy',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
    };
  },
  computed: {
    showModalPrivacyPolicy: {
      get() {
        return this.$store.getters.showModalPrivacyPolicy;
      },
      set(data) {
        this.$store.commit('setShowModalPrivacyPolicy', data);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';

  .modals-privacy-policy {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .8);

    &__content {
      max-width: 946px;
      width: 100%;
      border-radius: 10px;
      position: relative;
      background: $color-picton-blue;
      padding: 50px 35px 35px 35px;
    }

    &__text {
      background: $color-white;
      overflow: hidden;
      padding: 10px;
    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-white;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      line-height: 48px;
      font-size: 37px;
      color: $color-white;
      text-align: left;
      margin-bottom: 12px;
    }

    &__desc {
      font-family: $font-global;
      color: $color-black;
      line-height: 20px;
      font-size: 14px;
      padding-right: 10px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-family: $font-global-bold;
      font-size: 16px;
      margin: 20px 0 10px 0;
      padding-right: 10px;
    }

    &__scroll {
      max-height: 275px;
    }

    &__wrapper  {
      padding: 30px 20px 30px 30px;
      background: $color-white;
    }

    ol {
      font-size: 14px;
      list-style-position: inside;
      list-style-type: lower-latin;
      padding-right: 10px;
      line-height: 20px;

      ol {
        list-style-type: lower-roman;
        margin-left: 30px;
      }

      li {

      }
    }

    strong {
      font-weight: 700;
    }
    a {
      color: $color-black;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    em {
      font-style: italic;
    }
  }
</style>
